<template>
  <div>
    <el-dialog
      :title="'绑定房间'"
      :close-on-click-modal="false"
      top="5%"
      :visible.sync="showDialog"
      width="40%"
      label-width="80px"
    >
      <el-form
        :model="addform"
        ref="addform"
        :rules="myrules"
        label-width="100px"
      >
        <el-form-item label="品牌" prop="brandId">
          <el-select
            v-model="addform.brandId"
            @change="selectOne"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in brandSelectData"
              :key="index"
              :label="item.brandName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店" prop="hotelId">
          <el-select
            v-model="addform.hotelId"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in hotelSelectData"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间号" prop="roomNo">
          <el-input
            v-model.trim="addform.roomNo"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmBind('addform')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { gatewayBindRoom, getBrandSelect, getHotelSelect } from "@/api";
export default {
  data() {
    return {
      loading: false,
      showDialog: false,
      brandSelectData: null,
      hotelSelectData: null,
      addform: {
        hotelId: null,
        gatewayMac: null,
        roomNo: null,
      },
      type: 0,
      myrules: {
        brandId: [
          { required: true, message: "请输入", trigger: ["blur", "changge"] },
        ],
        hotelId: [
          { required: true, message: "请输入", trigger: ["blur", "changge"] },
        ],
        roomNo: [
          { required: true, message: "请输入", trigger: ["blur", "changge"] },
        ],
      },
    };
  },
  computed: {},

  created() {
    getBrandSelect().then((res) => {
      if (res.status === 200) {
        this.brandSelectData = res.data.data;
      }
    });
  },

  methods: {
    selectOne(id) {
      this.addform.hotelId = null;
      getHotelSelect(id).then((res) => {
        if (res.data.data.length > 0) {
          this.hotelSelectData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店数据",
            type: "warning",
          });
        }
      });
    },
    confirmBind(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          gatewayBindRoom(this.addform).then((_) => {
            this.showDialog = false;
            this.$emit("bindViewClose");
          });
          return true;
        }
        return false;
      });
    },

    openSet(data) {
      this.addform.gatewayMac = data.gatewayMac;
      this.showDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader {
  width: 50px !important;
  height: 50px !important;
}

.avatar-uploader-icon {
  font-size: 22px;
  color: #8c939d;
  height: 50px !important;
  line-height: 50px !important;
  text-align: center;
}

.avatar {
  height: 100px;
  padding: 5px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  overflow: hidden;

  .avatarImg {
    height: 100px;
  }

  .del {
    right: -100%;
    transition: all 0.5s;
    overflow: hidden;
  }

  &:hover {
    .del {
      transition: all 0.5s;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      position: absolute;
      z-index: 5;
      right: 0;
      top: 0;
      text-align: center;
      line-height: 25px;
      background-color: rgba(0, 0, 0, 0.5);

      .delBtn {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>


