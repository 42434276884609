<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form
          label-width="80px"
          class="topS"
          ref="seekData"
          :model="seekData"
        >
          <el-row :gutter="10">
            <el-col :span="4">
              <el-form-item label="主机MAC" prop="gatewayMac">
                <el-input v-model="seekData.gatewayMac" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="主机IP" prop="ip">
                <el-input v-model="seekData.ip" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="tab"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="gatewayMac"
              label="主机MAC"
            ></el-table-column>
            <el-table-column prop="ip" label="IP地址"></el-table-column>
            <el-table-column prop="online" label="在线状态">
              <template slot-scope="scope">
                <div>
                  {{ showOnline(scope.row.online) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="version"
              label="版本号"
            ></el-table-column>
            <el-table-column
              prop="registerTime"
              label="首次上线地址"
            ></el-table-column>
            <el-table-column
              prop="lastOnlineTime"
              label="最后上线地址"
            ></el-table-column>
            <el-table-column label="操作" width="280">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-circle-plus-outline"
                  v-if="showBind"
                  @click="openpopup(scope.row)"
                >
                  绑定
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>

    <bindView ref="add" @bindViewClose="bindViewClose" />
  </div>
</template>
<script>
import { getGatewayRegisterList, gatewayBindRoom } from "@/api";
import bindView from "./bindView";

export default {
  components: {
    bindView,
  },
  // name:"pp",
  data() {
    return {
      height: 0,
      selectList: [],
      loading: false,
      // 品牌列表数据
      tableData: [],
      // 查询的数据
      total: null,
      seekData: {
        gatewayMac: null,
        ip: null,
        pageSize: 10,
        pageNum: 1,
      },
      query: {},
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },

  computed: {
    // 权限
    showBind() {
      return this.$store.state.roleMenu.includes("gateway:bind:room");
    },
  },
  created() {
    // 调用初始化表格数据方法
    this.initialize();
    this.getList();
  },
  methods: {
    initialize() {},
    tab(list) {
      this.selectList = list;
    },
    openpopup(row) {
      this.$refs.add.openSet(row);
    },

    // 初始化表格数据
    getList() {
      this.loading = true;
      getGatewayRegisterList(this.seekData)
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    showOnline(val) {
      return val == 1 ? '在线' : '离线';
    },
    bindViewClose() {
      this.getList();
    },
    // 查询按钮
    handleSearch() {
      this.seekData.pageNum = 1;
      this.query = Object.assign({}, this.seekData);
      this.getList();
    },
    // 重置按钮
    reset() {
      this.seekData.pageNum = 1;
      this.$refs.seekData.resetFields();
      this.handleSearch();
    },
    //分页
    handleCurrentChange(pageNum) {
      this.seekData.pageNum = pageNum;
      this.getList();
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.avatarImg {
  height: 40px;
}
.my-box {
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .my-input {
    max-width: 209px;
  }
}
.checkBox {
  display: inline-block;
  width: 28%;
}
</style>
